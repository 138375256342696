body{
  padding: 0;
  margin: 0;
  padding: unset !important;
}
.App {
  text-align: center;
}
.responsiveText {
  font-size: xx-small;
}
/* タブレット */
@media screen and (min-width:430px) and (max-width: 600px){
  .responsiveText {
    font-size: medium;
  }
}
@media screen and (min-width:599px) and (max-width: 800px){
  .responsiveText {
    font-size: medium;
  }
}
/* PC */
@media screen and (min-width:800px){
  .responsiveText {
    font-size: large;
  }
}

/* ワイドモニター */
@media screen and (min-width:2560px) and (max-width:3839px) {
  .scrollToTopButton svg {
    height: 3vw !important;
    width: 3vw !important;
  }
  .responsiveText {
    font-size: x-large;
  }
}
@media screen and (min-width:3840px) {
  .responsiveText {
    font-size: xx-large;
  }
}