.header {
  display: flex;
  position: sticky;
  background-color: #ffffff;
  width: 100%;
  height: 5em;
}

.headerLeft {
  display: flex;
}

.headerLeft__object {
  display: flex;
  margin-left: -35%;
  width: 130%;
  align-items: center;
  text-align: right;
}

.headerLeft__object>p {
  display: flex;
  width: fit-content;
  font-size: 3.5vw;
  padding: 0px 0vw 5px 0vw;
  color: #243457;
}
.siteLogoRecruit {
  padding-left: 0 !important;
  padding-right: 10px !important;
  width: 65% !important;
  margin: unset !important;
  box-sizing: unset !important;
}

.headerNav {
  align-items: center;
}

.headerNav a {
  text-decoration: none;
  color: #243457;
}

.headerNav a:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* PC */
@media screen and (min-width:800px) {
  #header {
    z-index: 1;
    width: 100%;
    height: 40px;
    background: #002645;
  }

  #header.top-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 1100px;
    margin: 0 auto;
    background-color: transparent;
  }

  .header-inner {
    overflow: hidden;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 10px 0;
  }

  .header-inner .sologan h1 {
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
    color: #fff;
  }

  .header-inner .sologan {
    float: left;
    margin: 10px 0 20px;
  }

  .header-inner .sub_nav {
    position: relative;
    float: right;
    padding: 7px 0;
    color: #fff;
  }

  .header-inner .sub_nav a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 0 4px;
    color: #fff;
  }

  .header-inner .sub_nav a:hover {
    color: #ebad26;
  }

  .sankaku {
    border-top: 50px solid #ededed;
    border-right: 20px solid #ededed;
    border-bottom: 50px solid #f4f4f4;
    border-left: 20px solid #f4f4f4;
    position: absolute;
    top: 0;
    right: 383px;
  }

  #nav {
    width: 100%;
    margin: 0 auto;
    height: 130px;
    text-align: right;
  }

  #nav .menu-topnav-container {
    width: 1100px;
    margin: 0 auto;
    position: relative;
  }

  #nav ul {
    list-style: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-menu li {
    display: inline-block;
    position: relative;
    margin: 0 0 0 1px;
    text-align: center;
    width: 123px;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: #ededed -3px 1px 0px 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .nav-menu li a {
    display: block;
    color: #11334e;
    font-size: 13px;
    line-height: 15px;
    text-decoration: none;
    padding: 70px 0 19px 0;
    background-size: 44px;
    background-position: center 20px;
    background-repeat: no-repeat;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .nav-menu li.nav-service a {
    background-image: url(../image/company/nav2_off.png);
  }

  .nav-menu li.nav-about a {
    background-image: url(../image/company/nav3_off.png);
  }

  .nav-menu li.nav-recruit a {
    background-image: url(../image/company/nav1_off.png);
  }

  .nav-menu li.nav-contact a {
    background-image: url(../image/company/nav4_off.png);
  }

  .nav-menu li:hover,
  .nav-menu li.current {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #ebad26;
    color: #fff;
  }

  .nav-menu li.current a,
  .nav-menu li:hover a {
    color: #fff;
  }

  .nav-menu li.nav-service:hover a,
  .nav-menu li.nav-service.current a {
    background-image: url(../image/company/nav2.png);
  }

  .nav-menu li.nav-about:hover a,
  .nav-menu li.nav-about.current a {
    background-image: url(../image/company/nav3.png);
  }

  .nav-menu li.nav-recruit:hover a,
  .nav-menu li.nav-recruit.current a {
    background-image: url(../image/company/nav1.png);
  }

  .nav-menu li.nav-contact:hover a,
  .nav-menu li.nav-contact.current a {
    background-image: url(../image/company/nav4.png);
  }

  .mainbox2 {
    position: relative;
    width: 1100px;
    margin: auto;
  }

  .mainbox2 .logo {
    position: absolute;
    z-index: 80;
    top: -107px;
    left: -25px;
    width: 55%;
  }

  .mainbox2 .logo img {
    max-width: 100%;
  }

  div.breadcrumbs {
    font-size: 10px;
    position: absolute;
    z-index: 150;
    top: -42px;
    left: 96px;
    width: 80%;
    color: #002645;
  }

  div.breadcrumbs a {
    position: relative;
    padding: 3px 8px 2px 2px;
    color: #002645;
    background-color: #e8e8e8;
  }

  div.breadcrumbs a:hover {
    color: #aaa;
  }

  div.breadcrumbs a::before {
    position: absolute;
    top: 0;
    right: -16px;
    display: inline-block;
    content: '';
    border: 8px solid transparent;
    border-left-color: #e8e8e8;
  }

  div.breadcrumbs a::after {
    position: absolute;
    top: 0;
    left: -16px;
    display: inline-block;
    content: '';
    border: 8px solid transparent;
    border-top-color: #e8e8e8;
    border-right-color: #e8e8e8;
    border-bottom-color: #e8e8e8;
  }

  div.breadcrumbs a.home {
    padding: 3px 8px 2px 13px;
  }

  div.breadcrumbs a.home::after {
    border: none;
  }

  div.breadcrumbs span>span {
    position: relative;
    padding: 3px 8px 2px 2px;
    color: #002645;
    background-color: #fff;
  }

  div.breadcrumbs span>span::before {
    position: absolute;
    top: 0;
    right: -16px;
    display: inline-block;
    content: '';
    border: 8px solid transparent;
    border-left-color: #fff;
  }

  div.breadcrumbs span>span::after {
    position: absolute;
    top: 0;
    left: -15px;
    display: inline-block;
    content: '';
    border: 8px solid transparent;
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;
  }

  .headerLeft {
    width: 35%;
  }

  .headerLeft__object {
    width: 100%;
    margin-left: -120px;
  }
  .headerLeft__object>p {
    width: max-content;
    font-size: 25px;
    font-size: 1.5vw;
  }

  .header_Right_PC {
    width: max-content !important;
    align-items: center;
  }
}

/* ワイドモニター */
@media screen and (min-width:2560px){
  #header {
    height: unset;
  }
  .header-inner .sologan {
    margin: 20px 0 10px;
  }
  .header-inner .sologan h1 {
    font-size: 1vw;
  }
  .header-inner .sub_nav a {
    font-size: x-large;
  }
  .header-inner {
    padding-top: 5px;
  }
  #nav {
    height: 7vw;
  }
  #nav .menu-topnav-container, .mainbox2 {
    width: 75%;
  }
  .nav-menu li {
    width: 6.5vw;
    height: 6.5vw;
  }
  div.breadcrumbs {
    font-size: 2rem;
    left: 156px;
    top: -60px;
  }
  .mainbox2 .logo {
    top: -170px;
    left: -50px;
  }
  .nav-menu li a {
    font-size: 1.7rem;
    padding-top: 4.8vw;
    background-size: 50%;
  }
}
@media screen and (min-width:3840px){
  #nav {
    height: 4.6vw;
  }
  .nav-menu li {
    width: 4.5vw;
    height: 4.5vw;
  }
  .nav-menu li a {
    font-size: 1.7rem;
    padding-top: 3.5vw;
  }
  .header-inner .sub_nav a {
    font-size: 40px !important;
  }
}
@media screen and (min-width:5120px){
  div.breadcrumbs {
    font-size: 2.5rem;
    left: 196px;
    top: -70px;
  }
  .mainbox2 .logo {
    top: -210px;
    left: -50px;
  }
  .nav-menu li a {
    font-size: 2.2rem;
  }
}

/* タブレット */
@media screen and (min-width:430px) and (max-width: 600px) {
  .headerLeft {
    display: flex;
    width: 100%;
  }
  .headerLeft__object {
    display: flex;
    margin-left: -13%;
    width: 180px;
    align-items: center;
    text-align: right;
  }

  .headerLeft__object>p {
    display: flex;
    width: fit-content;
    margin-left: 30%;
    font-size: 1.5vw;
    padding: 0px 0vw 5px 0vw;
    color: #243457;
  }
}

@media screen and (min-width:599px) and (max-width: 800px) {
  .headerLeft {
    display: flex;
    width: 100%;
  }

  .headerLeft__object {
    display: flex;
    margin-left: -60px;
    width: 350px;
    align-items: center;
    text-align: right;
  }

  .headerLeft__object>p {
    display: flex;
    width: fit-content;
    margin-left: 40px;
    font-size: 1.5vw;
    padding: 0px 0vw 5px 0vw;
    color: #243457;
  }
}

.headerRight {
  display: flex;
  background-color: #fdfdfd;
}

.headerRight__object {
  display: flex;
  align-items: center;
}

/* headerRight Botton */
.headerRight__Botton {
  display: flex;
  width: 122px;
  height: 40px;
  border: 1px solid #44c4d4;
  background-color: #44c4d4;
  align-items: center;
}

.headerRight__Botton>p {
  color: white;
  align-items: center;
  cursor: pointer;
}

.headerRight__Botton:hover {
  background-color: #44c4d4;
}

.headerRight__placeHolder {
  display: flex;
  width: 122px;
  height: 40px;
}

.headerRight__MenuBotton {
  display: flex;
  width: 60px;
  height: 85%;
  margin: 5px 0px 6px 6px;
  border: 1px solid transparent;
  background-color: transparent;
}

.headerRight__MenuSharpIcon {
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  margin: 5px 0px 6px -8.7px;
  background-color: #243457;
}

.headerRight__MenuSharpIconFont>p {
  color: rgb(255, 255, 255);
  font-size: 12px;
  width: 50px;
  height: 33px;
  margin-bottom: 0;
  margin-top: 5px;
  margin: -6px 0px 0px -5px;
  background-color: transparent;
}

@media screen and (min-width:400px) and (max-width: 600px) {
  .headerRight {
    display: flex;
    width: 100%;
    margin-right: -20%;
    background-color: #fdfdfd;
  }

  .headerRight__object {
    display: flex;
    align-items: center;
  }

  /* headerRight MenuBotton*/
  .headerRight__MenuBotton {
    display: flex;
    width: 60px;
    height: 85%;
    margin: 5px 0px 6px 10px;
    border: 1px solid transparent;
    background-color: transparent;
  }

  .headerRight__MenuBotton:hover {
    background-color: #fdfdfd;
  }

  .headerRight__MenuSharpIcon {
    color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    margin: 5px 0px 6px -8.7px;
    background-color: #243457;
  }

  .headerRight__MenuSharpIconFont>p {
    color: rgb(255, 255, 255);
    font-size: 12px;
    width: 50px;
    height: 33px;
    margin-bottom: 0;
    margin-top: 5px;
    margin: -6px 0px 0px -5px;
    background-color: transparent;
  }
}

@media screen and (min-width:600px) {
  .headerRight {
    display: flex;
    width: 100%;
    margin-right: -40%;
    background-color: #fdfdfd;
  }

  .headerRight__object {
    display: flex;
    align-items: center;
    margin-right: 10%;
  }

  /* headerRight MenuBotton*/
  .headerRight__MenuBotton {
    display: flex;
    width: 60px;
    height: 85%;
    margin: 5px 0px 6px -16px;
    border: 1px solid transparent;
    background-color: transparent;
  }

  .headerRight__MenuBotton:hover {
    background-color: #fdfdfd;
  }

  .headerRight__MenuSharpIcon {
    color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    margin: 5px 0px 6px -8.7px;
    background-color: #243457;
  }

  .headerRight__MenuSharpIconFont>p {
    color: rgb(255, 255, 255);
    font-size: 12px;
    width: 50px;
    height: 33px;
    margin-bottom: 0;
    margin-top: 5px;
    margin: -6px 0px 0px -5px;
    background-color: transparent;
  }
}

/*List表示*/
.List {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  background-color: rgba(4, 11, 54, 0.5);
}

.List__LObject {
  background-color: rgb(255, 255, 255, 0);
  margin-top: 0;
  height: 100%;
  width: 100%;
}

.List__Item {
  position: relative;
  background-color: rgb(255, 255, 255, 0);
}

.List__ItemText {
  text-align: center;
}

.List__ItemText>span>a {
  color: #fdfdfd;
  margin-top: 30%;
  font-size: 100;
}