/* 共通 */
#header {
  display: none;
}

/* スマホ */
.mainbox {
  background: url(../image/company/mob-visual.png) no-repeat;
  background-position: bottom center;
}
.mainbox, .innerbox {
  width: 100%;
  height: 340px;
  background-position: center;
  margin: 0;
  background-size: cover;
}
.innerbox {
  position: relative;
  height: 100%;
  margin: auto;
}
#topLogo {
  z-index: 80;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  width: 13em;
  margin: 0 auto;
}
.top-copy {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  width: 240px;
  margin: auto;
}
#frontCopyPc {
  display: none;
}
#frontCopy {
  display: unset;
}
.top-recruit {
  width: 100%;
  display: table;
  float: right;
  height: 100%;
  background: #1f75bb;
}
.top-recruit a {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.top-recruit img {
  width: auto;
  max-height: 100px;
  transition: .3s ease;
}

.top-mainbox p.icon {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 20px auto;
  border-radius: 50%;
  background-color: #ebad26;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);
  -webkit-transition: .3s;
  transition: .3s;
}
.top-mainbox p.icon a {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.top-mainbox p.icon a:hover {
  border-radius: 50%;
  box-shadow: 0 15px 30px -5px rgba(0,0,0,.15), 0 0 5px rgba(0,0,0,.1);
  transform: translateY(-1px);

}
.top-mainbox p.icon img {
  width: 50px;
  padding-top: 19px;
}
.newsArea {
  padding-bottom: 2rem;
}
.newsArea div {
  display: inline-block;
}
.newsArea ul {
  margin: 0;
  padding: 0;
}
.newsArea ul li {
  padding: 0.3rem;
  text-decoration: underline;
}
.newsArea ul li a {
  font-size: larger;
  color: #002645;
}
.top-mainbox, .top-mainbox.center {
  padding: 10% 0;
  width: 100%;
}
.top-mainbox ul {
  margin: 35px auto 0;
  padding: 0;
}
.top-mainbox ul li {
  list-style: none;
  margin: 0 auto 15px;
}
.top-mainbox ul li a button {
  box-sizing: border-box;
  position: relative;
  display: block;
  margin: auto;
  padding: 15px 2%;
  -webkit-transition: .3s;
  transition: .3s;
  color: #fff;
  font-weight: bold;
  border: 1px solid #002645;
  background-color: #002645;
  font-size: small;
}
.top-mainbox ul li a button svg {
  vertical-align: text-top;
}
.corporate-top-link {
  text-decoration: none;
  color: #002645;
}
.wrapper.clearfix.textC {
  margin: 0 auto 0;
  padding-bottom: 30px;
}
.textC {
  text-align: center;
}

/* タブレット */
@media screen and (min-width:430px) and (max-width: 599px){
  .top-mainbox {
    min-height: 500px;
  }
}
@media screen and (min-width:600px) and (max-width: 799px){
  .top-mainbox {
    min-height: 650px;
  }
}
@media screen and (min-width:800px) and (max-width: 959px){
  .top-mainbox {
    min-height: 650px;
  }
}

/* PC */
@media screen and (min-width:960px) {
  #header {
    display: inherit;
  }
  .d-none-top {
    display: none !important;
  }
  #header.top-header {
    width: 100%;
    position: absolute;
  }
  .header-inner {
    overflow: hidden;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 10px 0;
  }
  .header-inner .top_nav {
    z-index: 80;
    margin-right: 110px;
  }
  .header-inner .sub_nav {
    position: relative;
    float: right;
    padding: 7px 0;
    color: #fff;
  }
  .header-inner .sub_nav a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 0 4px;
    color: #fff;
    transition: .2s ease;
  }
  .header-inner .sub_nav a:hover {
    color: #EBAD24;
  }
  .mainbox {
    width: 100%;
    min-width: 1100px;
    height: 600px;
    background: url(../image/company/visual.jpg) no-repeat;
    background-size: 108%;
  }
  #topLogo {
    top: 45px;
    left: 25%;
    width: 25%;
    margin: unset;
  }
  .top-copy {
    left: unset;
    margin: unset;
    right: 120px;
    bottom: 75px;
    width: 360px;
  }
  #frontCopyPc {
    display: unset;
  }
  #frontCopy {
    display: none;
  }
  .top-recruit img {
    max-height: 120px;
  }
  .top-recruit a div {
    max-height: 120px;
  }
  .top-recruit a:hover {
    opacity: .6;
  }
  .top-recruit a:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  .top-mainbox {
    min-height: 650px;
  }
  .top-mainbox ul li a button:hover {
    color: #002645;
    background-color: #fff;
    border: 1px solid #eee;
  }
  .top-mainbox p.icon {
    margin: 90px auto 20px;
  }
}

/* ワイドモニター */
@media screen and (min-width:2560px) {
  .header-inner {
    max-width: 65% !important;
  }
  .mainbox {
    height: 70vh;
  }
  .top-copy {
    right: 4vw;
    width: 20%;
  }
  .top-recruit img {
    max-height: unset;
    height: 11vh !important;
  }
  .top-recruit a div {
    max-height: unset;
  }
  .newsArea ul li a {
    font-size: 50px;
  }
  .top-mainbox {
    min-height: 800px;
  }
  .top-mainbox ul li a button {
    font-size: xx-large;
  }
  .top-mainbox ul li a button svg {
    height: unset;
    vertical-align: baseline;
  }
}
@media screen and (min-width:3840px){
  .newsArea h5 {
    font-size: 70px;
  }
  .top-mainbox h3 {
    font-size: 3.5rem;
  }
  .header-inner {
    max-width: 65% !important;
  }
  .top-mainbox {
    min-height: 1000px;
  }
  .top-mainbox p.icon {
    margin: 50px auto;
  }
  .top-mainbox p.icon {
    width: 5vw;
    height: 5vw;
  }
  .top-mainbox p.icon img {
    width: -webkit-fill-available;
    padding: 10%;
  }
  .top-mainbox ul {
    margin-top: 3vh;
  }
}