.slideStyle {
  margin: 0 15px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.profileStyle {
  font-Size: 11px;
  font-Weight: 500;
  text-align: left;
  margin: 8px 0px 0px 0px;
}  

.titleStyle {
  font-size: 16px;
  font-Weight: 600;
  text-align: left;
  padding-bottom: 3%;
  margin: 0 0;
}

@media screen and (min-width: 480px) {

  .sliderWrapper {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .sliderWrapper .slick-slide {
    width:calc(100% / 3) !important;
  }

  .slideStyle {
    max-width: 100%;
    height: auto;
  }
}
