a {
  text-decoration: none;
}
.companybox {
  margin: 0 0 30px 0;
  border-radius: 4px;
  background-color: #fff;
}
.companybox h3 {
  color: #002645;
  margin: 1em 0 0;
  font-weight: bold !important;
  padding: 0 0 15px 0;
  letter-spacing: 1px;
}
h3.consen {
  border-bottom: 1px #11334e dotted;
  margin: 15px 0 30px 0;
  color: #002645;
}
h3.underlineSP {
  border-bottom: 1px #11334e dotted;
}
.company-contents-with-tag {
  display: grid;
}
.company-contents-with-tag p {
  line-height: 190%;
  font-size: 14px;
}
.service-detail-list {
  color: #000;
  min-width: 120px;
  font-weight: bold !important;
  padding-bottom: 0.5em;
}
.company th {
  font-weight: bolder;
  text-align: left;
}
p.company-midashi {
  font-size: 16px;
  font-weight: bolder;
}
.bold_link a {
  font-weight: bold;
  text-decoration: none;
  color: #002645;
}
.moji13px {
  font-size: 13px;
  line-height: 170%;
  font-weight: normal;
}

/* スマホ */
@media screen and (max-width:800px){
  .companyHeader {
    align-items: center;
  }
  .companyHeader > div.headerLeft {
    width: 90% !important;
  }
  .companyHeader > .headerLeft > div.headerLeft__object {
    width: 100% !important;
  }
  .companyHeader > div.headerRight {
    width: unset !important;
  }
  .companyHeader > .headerRight > div.headerRight__object{
    margin: 0;
  }
  .spwrapper {
    padding: 5px 15px 10px;
  }

  .company tr {
    display: block;
  }
  .company th, .company td {
    display: list-item;
    list-style-type: none;
  }
  .companybox .visionTtl p {
    font-weight: bold;
  }
  .company-privacy-policy {
    overflow: auto;
  }
}

#footer {
    font-size: 80%;
    position: absolute;
    clear: both;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #e5e5e5;
    background: #fff;
  }
  .footer-inner {
    margin: 0 auto;
    padding: 5% 0;
    width: 95%;
  }
  div#footer_navi {
    margin-right: auto;
    margin-left: auto;
  }
  div#footer_navi .adress {
    float: none;
    width: 90%;
    margin-bottom: 40px;
    padding-left: 5%;
    margin-right: 30px;
  }
  .footlogo a {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
  .footlogo a img.logo1 {
    display: none;
  }
  .footlogo img {
    width: 100%;
  }
  /* PC */
  @media screen and (min-width:800px){
    * {
      margin: 0;
      vertical-align: baseline;
    }
    body {
      font-size: 14px;
      line-height: 180%;
      color: #000;
      background-color: #f4f4f4;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    p {
      font-size: 14px;
      line-height: 190%;
      color: #000;
    }
    .clearfix:after {
      content: " ";
      display: block;
      clear: both;
    }

    .wrapper {
      max-width: 79vw;
      margin: auto;
      padding: 0 0 60px;
    }
    .company {
      float: left;
      width: 100%;
      margin: 15px 60px 0 0;
      padding: 0;
      font-size: 14px;
    }
    .company td {
      padding: 0 0 20px 0;
    }
    .company th {
      padding: 0 0 30px 0;
    }
    p.company-title {
      font-size: 18px;
      font-weight: bolder;
    }
    .companybox {
      padding: 3% 5%;
    }
    .companybox p.aisatu {
      width: 71%;
      padding-left: 30px;
    }
    .companybox p.aisatu {
      font-size: 16px;
    }
    .companybox p.rinen {
      font-size: 18px;
    }
    .companybox .visionTtl {
      width: 120px;
      text-align: left;
      clear: both;
    }
    .companybox .group {
      font-weight: bold;
    }
    .bold_link {
      font-size: 16px;
      line-height: 1.4;
    }
    .bold_link a:hover {
      border: 0;
      color: #ebad26;
    }
    .bold_link a::before {
      margin: 0 4px;
      font-size: 18px;
      font-weight: normal;
      vertical-align: middle;
    }
    .companybox h3 {
      font-size: 30px;
    }
    .bold_link a {
      transition: .2s ease;
  }
  .companybox-service h3 {
    color: #002645;
    line-height: 1.1em;
  }
  h3.underlineSP {
    border-bottom: unset;
  }
  .service-description {
    margin: 5% 0;
    padding: 0 5%;
    border-left: solid 1px;
  }
  .service-desc-text {
    display: flex;
  }
  .service-detail-list {
    padding-bottom: unset;
  }
  .footlogo a {
    width: 100%;
  }
  .footerList li a:hover {
    color: #ccc;
    transition: .3s;
  }
}

  .footerList {
    padding: unset;
  }
  .footerList li ul {
    padding: unset;
    text-align: left;
  }
  .footerLinkCategory {
    font-weight: bold;
  }
  .footerList li a {
    display: block;
    text-decoration: none;
    color: #002645;
    padding-bottom: 9px;
  }
  li {
    list-style: none;
  }
  #copyright {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    position: relative;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding: 10px 0;
    text-align: center;
    letter-spacing: 1px;
    color: #999;
    background: #002645;
  }
  #copyright a {
    color: #ebad26;
  }

/* ワイドモニター */
@media screen and (min-width:2560px) {
  body {
    font-size: xx-large;
  }
  p, .company th, .company td, p.company-midashi, .bold_link, .moji13px {
    font-size: 2.5rem !important;
  }
  .wrapper {
    max-width: 90vw;
  }
  .service-detail-list {
    padding-right: 20px;
  }
  .bold_link a svg, .service-detail-list svg, .service-desc-text p svg .company-midashi svg {
    font-size: inherit;
  }
  .companybox .visionTtl {
    width: unset;
  }
  p.company-title {
    font-size: 3rem;
  }
  #header {
    height: auto;
  }
  .footerList {
    font-size: xx-large;
  }
  .privacymark img {
    width: 50%;
  }
  .companybox h3 {
    font-size: 3.5rem;
  }
}