.pageHeaderImage img {
  width: 11em;
  height: 7.5em;
}
.pageHeaderTextImg img {
  width: auto;
  height: 5em;
}
@media screen and (min-width: 600px) {
  .pageHeaderImage img {
    width: 13em;
    height: 9em;
  }
  .pageHeaderTextImg img {
    height: 6.5em;
  }
}
@media screen and (min-width: 960px) {
  .pageHeaderImage img {
    width: 13em;
    height: 9em;
  }
  .pageHeaderTextImg img {
    height: 6.5em;
  }
}
@media screen and (min-width: 1280px) {
  .pageHeaderImage img {
    width: 17em;
    height: 12em;
  }
  .pageHeaderTextImg img {
    height: 10em;
  }
}
@media screen and (min-width: 1920px) {
  .pageHeaderImage img {
    width: 17vw;
    height: 12vw;
  }
  .pageHeaderTextImg img {
    height: 11em;
  }
}